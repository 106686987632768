/* App.css */
body {
  font-family: 'Mitr', sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.marquee {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  height: 40px;
  bottom: 0;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear 3;
  width: 100%;
  padding-top: 5px;
  font-weight: 200;
}

.imageMediaMarquee {
  position: absolute;
  z-index: 1000;
  width: 14vw;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  height: 30vh;
  bottom: 35px;
  right: 10px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  transition: width 0.3s ease, height 0.3s ease;
}

.videoMediaMarquee {
  position: absolute;
  z-index: 1000;
  width: 14vw;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  height: 30vh;
  bottom: 35vh;
  right: 10px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  transition: width 0.3s ease, height 0.3s ease;
}

.collapseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.mediaContent {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* ใช้ cover เพื่อให้รูปหรือวิดีโอครอบคลุมทั้งกรอบ */
  border-radius: 10px;
}

.mediaOverlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2em;
  z-index: 1100;
}


.mapboxgl-ctrl-attrib {
  display: none;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-popup-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.new-issue-animation {
  animation: pulseAnimation 2s infinite;
}

.pin-container {
  display: inline-block;
  cursor: pointer;
}

.pin {
  width: 20px;
  height: 20px;
  background-color: #ff5252;
  border-radius: 50%;
  position: relative;
}

/* .pin::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 2px;
  height: 15px;
  background-color: #ff5252;
  transform: translateX(-50%);
} */

.pin-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #FF4532;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notification-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  overflow-y: auto;
}

.list-item {
  display: flex;
  align-items: center;
}

.list-item img,
.list-item video {
  margin-left: 10px;
}

.video-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.image-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  background-color: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}